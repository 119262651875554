import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShop } from '@fortawesome/free-solid-svg-icons'

export default function List() {
    const navigate = useNavigate();

    const [empresa, setEmpresa] = useState(myConstClass.VAR_GLOBAL.baseprincipal);

    useEffect(()=>{
        fetchAlquileres() 
    },[])

    const fetchAlquileres = async () => {        
    }    

    const changeEmpresa = async (e) => {        
        e.preventDefault();
        myConstClass.VAR_GLOBAL.baseprincipal = empresa;
        let empresa_div = document.getElementById("input_global-empresa");
        console.log(empresa_div);
        empresa_div.innerText = myConstClass.VAR_GLOBAL.empresas[empresa].name;
        console.log(empresa);        
        navigate("/")
    }

    return (
      <div className="container">
          <div className="row">
            <div className="col-12">                
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Cambiar empresa</h4>
                        <hr />                        
                        <Form onSubmit={changeEmpresa}>
                            <div key={`default-radio`} className="mb-3">
                                <Form.Check 
                                    type="radio"
                                    id={`default-radio`}
                                    name={`input_empresa`}
                                    label={`BRIVET SRL`}
                                    value={`brivet`}
                                    checked={empresa === 'brivet'}
                                    onChange={e => setEmpresa(e.currentTarget.value)}
                                />
                                <Form.Check                            
                                    type="radio"
                                    id={`default-radio`}
                                    name={`input_empresa`}
                                    label={`FCB SRL`}
                                    value={`fcb`}
                                    checked={empresa === 'fcb'}
                                    onChange={e => setEmpresa(e.currentTarget.value)}
                                />
                            </div>                            
                            <Button variant="primary" className="mt-2" block="block" type="submit">
                                <FontAwesomeIcon icon={faShop}/> Cambiar empresa
                            </Button>
                        </Form>
                    </div>   
                </div>
            </div>
          </div>
      </div>
    )
}
