import React, { useEffect, useState, useRef } from 'react'; /**PRIMERO */
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import "../../App.css";
import {useReactToPrint} from 'react-to-print'

const ReactPdfPrint = () => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle : 'emp-data',
        onAfterPrint: () => alert('Print success')

    });
    return (
        <>
            <div className="contenedorImpresion">                
                <div 
                    ref = {componentRef} 
                    style={{
                        width: '100%', 
                        height: window.innerHeight
                    }}
                >
                    <h1>DATOS DE IMPRESION</h1>
                </div>
            </div>
            <Button onClick ={handlePrint}> IMPRIMIR</Button>
        </>
    );
}

export default ReactPdfPrint;
