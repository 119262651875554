import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareUpRight } from '@fortawesome/free-solid-svg-icons'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import imgThumb from '../../imagen_prev.jpg';

export default function ListDeudas() {

    const [tablaDias, setTablaDias] = useState({})

    const [clientesdeudores, setClientesDeudores] = useState([])
    const [tableShow, setTableShow] = useState([])

    const [tabla1, setTabla1] = useState([])
    const [tabla2, setTabla2] = useState([])
    const [tabla3, setTabla3] = useState([])

    const [name, setName] = useState('');

    

    useEffect(()=>{
        /* if (!("Notification" in window)) {
          console.log("This browser does not support desktop notification");
        } else {
          Notification.requestPermission();
        } */
        fetchAlquileres() 
    },[])

    const fetchAlquileres = async () => {
        await axios.get(`${myConstClass.URL_APP}/api/${myConstClass.VAR_GLOBAL.baseprincipal}/getDeudasDias`).then(({data})=>{
            
            let tabla    = data.tabla ;
            let tabla_dias = data.dias[0];
            setTablaDias(tabla_dias);
            /* console.log(tabla_dias); */

            let nuevoArray    = [] ;
            let arrayTemporal = [] ;
            
            for(var i=0; i<tabla.length; i++){
                arrayTemporal = nuevoArray.filter(resp => resp["cod_cliente"] == tabla[i]["c_codigo"])
                if(arrayTemporal.length>0){
                    let stringDatePreviuous = nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["dateFirstDeuda"];
                    let datePrevious = new Date(stringDatePreviuous);
                    let dateLast = new Date(tabla[i]["d_fecha"]);
                    
                    nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Saldo"] = nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Saldo"] + Math.floor(tabla[i]["saldoSoles"] * 1000)/1000   ;
                    nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Pagado"] = nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Pagado"] + Math.floor(tabla[i]["pagado"] * 1000)/1000  ;
                    nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Documentos"].push(tabla[i]);
                    /* console.log(stringDatePreviuous, tabla[i]["d_fecha"],  myConstClass.help_dates.compare(datePrevious,dateLast)); */
                    if(myConstClass.help_dates.compare(datePrevious,dateLast) == 1 ){
                        nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["dateFirstDeuda"] = tabla[i]["d_fecha"];
                        nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["daysFirstDeuda"] = tabla[i]["nrodias"];
                    }
                    /* if(tabla[i]["proovedor"] === "NOVAFARMA S.A.C."){
                        console.log(nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]);
                    } */
                }else{
                    nuevoArray.push(
                        {
                            "cod_cliente" : tabla[i]["c_codigo"] , 
                            "Cliente" : tabla[i]["proveedor"] , 
                            "Pagado" : Math.floor(tabla[i]["pagado"] * 1000)/1000 , 
                            "Saldo" : Math.floor(tabla[i]["saldoSoles"] * 1000)/1000 , 
                            "dateFirstDeuda" : tabla[i]["d_fecha"] ,
                            "daysFirstDeuda" : tabla[i]["nrodias"],
                            "Documentos" : [tabla[i]]
                        }
                    )
                }
            }
            setClientesDeudores(nuevoArray);
            setTableShow(nuevoArray);

            let tablaDias1 = [] ;
            nuevoArray.forEach(function(item, i){
                if( parseInt(item.daysFirstDeuda) >= parseInt(tabla_dias.desde1) && parseInt(item.daysFirstDeuda) <= parseInt(tabla_dias.hasta1)){
                    tablaDias1.push(item);
                    return;
                }
            });
            setTabla1(tablaDias1);

            let tablaDias2 = [];
            nuevoArray.forEach(function(item, i){
                if( parseInt(item.daysFirstDeuda) >= parseInt(tabla_dias.desde2) && parseInt(item.daysFirstDeuda) <= parseInt(tabla_dias.hasta2)){
                    tablaDias2.push(item);
                    return;
                }
            });
            setTabla2(tablaDias2);

            let tablaDias3 = [];
            nuevoArray.forEach(function(item, i){
                if( parseInt(item.daysFirstDeuda) >= parseInt(tabla_dias.desde3) && parseInt(item.daysFirstDeuda) <= parseInt(tabla_dias.hasta3)){
                    tablaDias3.push(item);
                    return;
                }
            });
            setTabla3(tablaDias3);

            /* if(tablaDias2.length>0){                
                Notification.requestPermission().then( function( permission )
                {
                    if ( permission != "granted" )
                    {
                        alert( "Notification failed!" );
                        return;
                    }

                    navigator.serviceWorker.ready.then( function( registration )
                    {
                        registration.showNotification( "CLIENTES DEUDORES", { 
                            body: tablaDias2.length + " clientes con " + tabla_dias.desde2 + " dias" ,
                            vibrate: [200, 100, 200, 100, 200, 100, 200],
                            tag: 'vibration-sample'
                        } );
                    } );

                } );
            } */

            
        })
    }   

    const filter = (e) => {
        const keyword = e.target.value;
    
        if (keyword !== '') {
            const results = tableShow.filter((item) => {
                return item.Cliente.toLowerCase().includes(keyword.toLowerCase());
                /* item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()), */
                // Use the toLowerCase() method to make it case-insensitive
            });
            setTableShow(results);
        } else {
            setTableShow(clientesdeudores);
          // If the text field is empty, show all users
        }
    
        setName(keyword);
      };

    /* const deleteAlquiler = async (id) => {
        const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }

          await axios.delete(`${myConstClass.URL_APP}/api/alquiler/${id}`).then(({data})=>{
            Swal.fire({
                icon:"success",
                text:data.message
            })
            fetchAlquileres()
          }).catch(({response:{data}})=>{
            Swal.fire({
                text:data.message,
                icon:"error"
            })
          })
    } */
    const columns = [
        {
            id: 'action',
            name: '',
            maxWidth:'30px !important',
            minWidth:'initial !important',
            compact:true,
            cell: row => (
                <Link to={`/pagos/pagar/${row.cod_cliente}`} className='btn btn-outline-primary btn-sm'>
                    <FontAwesomeIcon icon={faSquareUpRight}/>
                </Link>
            ),
            /* style: {
            	"padding-left": "0px",                
            	"padding-right": "0px"
            }, */
        },
        {
            id: 'cliente',
            name: 'Cliente',
            maxWidth:'190px !important',
            width:'190px !important',
            minWidth:'190px !important',
            selector: row => row.Cliente,            
            sortable: true,
            reorder: true,            
            wrap:true,
            style: {
            	"padding-left": "10px",                
            	"padding-right": "10px"
            },
        },
        {
            id: 'deuda',
            name: 'Saldo T.',
            maxWidth:'100px !important',
            minWidth:'100px !important',
            selector: row => parseFloat((Math.floor(row.Saldo * 100)/100).toFixed(2)),            
            cell: row => (
                <span> S/. {(Math.floor(row.Saldo * 100)/100).toFixed(2)}</span>
            ),
            sortable: true,            
            right: true,
            grow:2,
            reorder: true,
            style: {
            	"padding-left": "10px",                
            	"padding-right": "10px"
            },
        },
        {
            id: 'dias',
            name: 'Dias',
            selector: row => parseInt(row.daysFirstDeuda),
            sortable: true,
            center: true,
            grow:1,
            reorder: true,
            style: {
            	"padding-left": "10px",                
            	"padding-right": "10px"
            },
        },
        {
            id: 'desde',
            name: 'Desde',
            selector: row => row.dateFirstDeuda,
            /* maxWidth:'120px !important',
            minWidth:'initial !important', */
            sortable: true,
            reorder: true,
            style: {
            	"padding-left": "10px",                
            	"padding-right": "10px"
            },
        },
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    return (
      <div className="container">
          <div className="row">
            <div className='col-12'>
                <div className="input-group mb-3 input-group-sm">
                    <span className="input-group-text" id="basic-addon1">Buscar</span>
                    <input
                        type="search"
                        value={name}
                        placeholder="" 
                        aria-label="Username"
                        onChange={filter}
                        className="form-control"
                    />
                </div>
                
                {/* <Link className='btn btn-primary mb-2 float-end' to={"/alquiler/create"}>
                    Create Alquiler
                </Link> */}
            </div>
            <div className="col-12">
                <div className="card card-body">
                    <div className="table-responsive">
                    <Tabs
                        defaultActiveKey="all"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        fill
                        >
                        <Tab eventKey="all" tabClassName="bg-default" title="Todos">
                            <h3>Todos</h3>
                            <DataTable
                                columns={columns}
                                data={tableShow}
                                pagination paginationComponentOptions={paginationComponentOptions}
                            />
                        </Tab>                        
                        <Tab 
                            eventKey="tabla1" 
                            tabClassName="bg-success text-bg-success" 
                            title={`(${tabla1.length})Hasta ` + tablaDias.hasta1}
                        >
                            <h3>{`Hasta ` + tablaDias.hasta1 + `dias` }</h3>
                            <DataTable
                                columns={columns}
                                data={tabla1}
                                pagination paginationComponentOptions={paginationComponentOptions}
                            />
                        </Tab>
                        <Tab 
                            eventKey="tabla2" 
                            tabClassName="bg-warning  text-bg-warning " 
                            title={`(${tabla2.length})Hasta ` + tablaDias.hasta2}
                        >
                            <h3>{`Hasta` + tablaDias.hasta2+ `dias`  }</h3>
                            <DataTable
                                columns={columns}
                                data={tabla2}
                                pagination paginationComponentOptions={paginationComponentOptions}
                            />
                        </Tab>
                        <Tab 
                            eventKey="tabla3" 
                            tabClassName="bg-danger text-bg-danger" 
                            title={`(${tabla3.length})Mayor ` + tablaDias.desde3}
                        >                            
                            <h3>{`Más de ` + tablaDias.desde3+ `dias`  }</h3>
                            <DataTable
                                columns={columns}
                                data={tabla3}
                                pagination paginationComponentOptions={paginationComponentOptions}
                            />
                        </Tab>
                    </Tabs>
                    
                        {/* <table className="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Debe desde</th>
                                    <th>Dias</th>
                                    <th className="text-end">Deuda</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {                                    
                                    tableShow.length > 0 && (
                                        tableShow.map((row, key)=>(
                                            <tr key={key}>
                                                <td>{row.Cliente}</td>
                                                <td> {row.dateFirstDeuda} </td>
                                                <td> {row.daysFirstDeuda} </td>
                                                <td className="text-end">{ (Math.floor(row.Saldo * 100)/100).toFixed(2) }</td>
                                                <td>
                                                    <img width="50px" alt="" src={`${myConstClass.URL_APP}/alquiler/image/${row.imagen}`} />
                                                </td>
                                                <td>
                                                    <Link to={`/pagos/pagar/${row.cod_cliente}`} className='btn btn-success me-2'>
                                                        PAGAR
                                                    </Link>
                                                    <Button variant="danger" onClick={()=>deleteAlquiler(row.id)}>
                                                        AÑ
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table> */}
                    </div>
                </div>
            </div>
          </div>
      </div>
    )
}
