import * as React from "react";
import {useEffect, useState } from 'react';
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import * as myConstClass from './constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShop } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { BrowserRouter as Router , Routes, Route, Link } from "react-router-dom";


/* CONFIGURACION */

import ConfigCambiarEmpresa from "./components/views_config/empresa.component";

/* import EditAlquiler from "./components/alquiler/edit.component";
import CreateAlquiler from "./components/alquiler/create.component";
import AlquilerList from "./components/alquiler/list.component"; */

/* MANTENEDORES */
import TipoPagoEditar from "./components/tipopago/edit.component";
import TipoPagoCrear from "./components/tipopago/create.component";
import TipoPagoList from "./components/tipopago/list.component";

import CorrelativoTrabajadorEdit from "./components/persona/editCorrelativo.component";
import CorrelativoTrabajadorList from "./components/persona/listCorrelativo.component";

/* OPERACIONES */

import DeudasList from "./components/pagos/listDeudas.component";
import PendientesList from "./components/pagos/listPagosPendientes.component";
import PagarDocumento from "./components/pagos/pagar.component";

import PrintExample from "./components/imprimir/example.component";

function ModalMenu(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >      
      <Modal.Body className="text-center">
      <Link to={`/`} onClick={props.onHide} className="btn btn-primary btn-lg mb-2">
        E.E.C.C. de Clientes
      </Link>
      <br />
      <Link to={`/pagos/pendientes`} onClick={props.onHide} className="btn btn-primary btn-lg mb-2">
        Pendientes de verificar
      </Link>
      <br />
      <Link to={`/tipopago/`} onClick={props.onHide} className="btn btn-primary btn-lg">
        Tipos de pago
      </Link>
      <br />
      <Link to={`/correlativo_trabajador/`} onClick={props.onHide} className="btn btn-primary btn-lg">
        Correlativos
      </Link>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={props.onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
    </Modal>
  );
}

function ModalUserConfig(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >      
      <Modal.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={props.onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
    </Modal>
  );
}

export default function App() {

  const [modalMenuShow, setModalMenuShow] = React.useState(false);
  const [modalUserShow, setModalUserShow] = React.useState(false);

  const [empresa, setEmpresa] = useState("");

  useEffect(()=>{
      cargarEmpresaSeleccionada() 
  },[])

  const cargarEmpresaSeleccionada = () => {
    setEmpresa(myConstClass.VAR_GLOBAL.empresas[myConstClass.VAR_GLOBAL.baseprincipal].name);
  }
  
  return (
    <Router>
      <Navbar id="navbar-master" bg="primary">
        <Container>
          <ButtonGroup aria-label="Basic example">
            <Button variant="primary" onClick={() => setModalMenuShow(true)}>
              <FontAwesomeIcon icon={faBars}/>
            </Button>
            <Button variant="primary" onClick={() => setModalUserShow(true)}>
              <FontAwesomeIcon icon={faUser}/>
            </Button>
          </ButtonGroup>          
          <Link to={"/"} className="navbar-brand text-white">
            Control interno
          </Link>        
          <Link style={{float: 'Left'}} to={`/views_config/empresa/`} className="navbar-brand text-white">
            <FontAwesomeIcon icon={faShop}/> <span id={"input_global-empresa"}>{empresa}</span>
          </Link>
        </Container>
      </Navbar> 
      <Container className="mt-15">
        <Row>
          <Col md={12}>
            <Routes>
              {/* CONFIGURACION */}
              <Route path="/views_config/empresa" element={<ConfigCambiarEmpresa />} />   
              
              {/* MANTENEDOR TIPO PAGO*/}
              <Route path="/tipopago/create" element={<TipoPagoCrear />} />
              <Route path="/tipopago/edit/:id" element={<TipoPagoEditar />} />
              <Route path="/tipopago/" element={<TipoPagoList />} />

              {/* MANTENEDOR TRABAJADOR*/}
              <Route path="/correlativo_trabajador/" element={<CorrelativoTrabajadorList />} />
              <Route path="/correlativo_trabajador/edit/:id" element={<CorrelativoTrabajadorEdit />} />

              {/* <Route path="/alquiler/create" element={<CreateAlquiler />} />
              <Route path="/alquiler/edit/:id" element={<EditAlquiler />} /> */}
              <Route path="/pagos/pagar/:id" element={<PagarDocumento />} />
              <Route path="/imprimir/example" element={<PrintExample />} />
              <Route path="/pagos/pendientes" element={<PendientesList />} />
              <Route exact path='/' element={<DeudasList />} />
            </Routes>
          </Col>
        </Row>
      </Container>
      <ModalMenu
        show={modalMenuShow}
        onHide={() => setModalMenuShow(false)}
      />
      <ModalUserConfig
        show={modalUserShow}
        onHide={() => setModalUserShow(false)}
      />
    </Router>);
}
