import React, { useEffect, useState, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import * as myConstClass from '../../constantes.js';
import "../../App.css";
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import InputGroup from 'react-bootstrap/InputGroup';
import {useReactToPrint} from 'react-to-print'
import imgThumb from '../../thumb.svg';
import Resizer from "react-image-file-resizer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function PagarDeuda() {
  const navigate = useNavigate();

  const { id } = useParams()

  const [cliente, setCliente] = useState("")
  const [pagado, setPagado] = useState("0")
  const [deuda, setDeuda] = useState("")
  
  const [validationError,setValidationError] = useState({})
  const [dataDeudas, setDataDeudas] = useState([])


  const [dataTipoPago, setDataTipoPago] = useState([])
  const [dataCaja, setDataCaja] = useState([])  
  

  const [indexComplete, setIndexComplete] = useState("-1")

  const [dataPagos, setDataPagos] = useState([])
  const [dataPagosGrupo, setDataPagosGrupo] = useState([])

  /* AREA PAGO TEMPORAL */
  const [codkey, setCodKey] = useState("0")
  const [tipoPago, setTipoPago] = useState("-1")
  const [caja, setCaja] = useState("-1")
  const [documento, setDocumento] = useState("-1")
  const [monto, setMonto] = useState("0")
  const [observacion, setObservacion] = useState("")
  const [imagenPago, setImagenPago] = useState("");
  const [imageView, setImageView] = useState(imgThumb);
  
  const [modalRegistroShow, setModalRegistroShow] = useState(false)

  const [dataPrint, setDataPrint] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const [isLoading, setLoading] = useState(false);

  const handleCloseModalRegistro = () => {
    setModalRegistroShow(false);
    resetFormPago();
  }
  const handleShowModalRegistro = () => setModalRegistroShow(true);

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle : 'emp-data',
        /* onAfterPrint: () => alert('Print success') */
    });

    const changeHandlerImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImagenPago(event.target.files[0]);
            Resizer.imageFileResizer(
                event.target.files[0],
                500,
                800,
                "JPEG",
                100,
                0,
                (uri) => {
                    /* console.log(uri); */
                    setImageView(uri)
                },
                "base64",
                200,
                200
              );
            /* setImageView(URL.createObjectURL(event.target.files[0])); */
        }
	};
    
    /**REQUIERE QUE DE EN SERVIDOR SEA POR DEFAULT IGUAL QUE AQUI = .JPEG */
    const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        80,
        0,
        (uri) => {
            /* console.log(uri); */
        /* console.log(uri); */
          resolve(uri);
        },
        "base64",
        200,
        200
      );
    });

    const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        /* console.log(mimeString); */
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
      };


  useEffect(()=>{
    fetchProduct()
    
  },[])

  const fetchProduct = async () => {
    await axios.get(`${myConstClass.URL_APP}/api/${myConstClass.VAR_GLOBAL.baseprincipal}/getDeudasCliente/${id}`).then(({data})=>{
        let _saldo = 0;
        let _pagado = 0;
        /* console.log(data); */
        for(var i=0; i<data.length; i++){
            _saldo = _saldo + parseFloat(data[i]["saldoSoles"]);
            /* console.log(data[i]["saldoSoles"], ' ', parseFloat(data[i]["saldoSoles"]) , ' - ' ,_saldo , ' | FINAL' , (1.2 + 1.8)) */
        }
        let _index = -1;
        const newdata = data.map(item => { 
            _index = parseInt(_index) + 1 ;
            return {
              ...item,
                indexTemp: _index
            };
        });
        setDataDeudas(newdata);
        /* console.log(newdata); */
        setPagado(_pagado);
        setDeuda(_saldo);
        /* setDeuda(myConstClass.round(_saldo, 4)); */
        setCliente(data[0]["proveedor"])

    });
    await axios.get(`${myConstClass.URL_APP}/api/${myConstClass.VAR_GLOBAL.baseprincipal}/mantenedor/tipopago`).then(({data})=>{
        setDataTipoPago(data);
    });    
    await axios.get(`${myConstClass.URL_APP}/api/${myConstClass.VAR_GLOBAL.baseprincipal}/mantenedor/caja`).then(({data})=>{
        setDataCaja(data);
    });
    /* console.log(new Date("2022-09-22").toLocaleDateString(), myConstClass.get_date("2022-09-22") ); */

  }

  const actualizarMontos = (updatedData) => {
    let _saldo = 0;
    let _pagado = 0;
    /* console.log('montos', dataDeudas); */
    updatedData.map(item => {
        _saldo = _saldo + parseFloat(item.saldoSoles);
        _pagado = _pagado + parseFloat(item.customPagando ? item.customPagando : 0);
    });
    /* console.log(updatedData); */
    /* for(var i=0; i<dataDeudas.length; i++){
        _saldo = _saldo + parseFloat(dataDeudas[i]["saldoSoles"]);
        _pagado = _pagado + parseFloat(item.customPagando ? item.customPagando : 0);
    } */
    setPagado(_pagado);
    setDeuda(_saldo);    
  }

    /* const changeHandler = (event) => {
		setImage(event.target.files[0]);
	}; */

    const resetFormPago = () =>{
        setCodKey("0");
        setTipoPago("-1");
        setDocumento("-1");
        setCaja("-1");        
        setMonto("0");    
        setObservacion("");
        setImagenPago("");
        setStartDate(new Date());
        setImageView(imgThumb);
    }

    const generarSaldosPagos = (data) => {

        const data_temp = [];
        const modifiedData = data.map(item => {
            let getDeudaTotalDocumento = 0;
            let getAmortizacionRegistered = 0;
            dataDeudas.forEach(function(docDeuda, i){
                if(String(docDeuda.numDoc).replace(/ /g, "") == item.documento_cod ){  
                    getDeudaTotalDocumento = docDeuda.saldoSoles;
                    return ;
                }
            });
            data_temp.forEach(function(docDeuda, i){
                if(docDeuda.documento_cod == item.documento_cod ){  
                    getAmortizacionRegistered = parseFloat(getAmortizacionRegistered) + parseFloat(docDeuda.monto) ;
                    return ;
                }
            });
            let saldo_ = parseFloat(getDeudaTotalDocumento) - (parseFloat(getAmortizacionRegistered) + parseFloat(item.monto));
            data_temp.push(item);
            return {
                ...item,
                "saldo" : saldo_
            };
        });
        setDataPagos(modifiedData);
        agruparPagos(modifiedData);

    }


    const agruparPagos = (data) => {
        let nuevoArray    = []
        let arrayTemporal = []
            
        for(var i=0; i<data.length; i++){
            arrayTemporal = nuevoArray.filter(resp => (resp["tipopago_cod"] == data[i]["tipopago_cod"] && resp["caja_cod"] == data[i]["caja_cod"]))
            if(arrayTemporal.length>0){                
                nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["monto"] = parseFloat(nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["monto"]) + Math.floor(data[i]["monto"] * 1000)/1000   ;
                nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["pagos"].push(data[i]);
                /* console.log(stringDatePreviuous, data[i]["d_fecha"],  myConstClass.help_dates.compare(datePrevious,dateLast)); */
                
                /* if(data[i]["proovedor"] === "NOVAFARMA S.A.C."){
                    console.log(nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]);
                } */
            }else{
                nuevoArray.push(
                    {
                        "akey" : myConstClass.generateId(20) , 
                        "tipopago_cod" : data[i]["tipopago_cod"] , 
                        "tipopago_str" : data[i]["tipopago_str"] , 
                        "caja_cod" : data[i]["caja_cod"] , 
                        "caja_str" :data[i]["caja_str"] ,
                        "monto" : data[i]["monto"],
                        "image_required" : data[i]["image_required"],
                        "imagen" : data[i]["imagen"],
                        "image_64" :data[i]["image_64"],                                   
                        "imagen_original" : data[i]["imagen_original"],
                        "imagen_comprimida" : data[i]["imagen_comprimida"],
                        "pagos" : [data[i]]
                    }
                )
            }
        }
        setDataPagosGrupo(nuevoArray);

    }

    
    const createPagoTemp = async (e) => {        
        e.preventDefault();
        let mensaje = "";

        if (tipoPago === "-1"){
            mensaje = "Debe seleccionar un tipo de pago obligatoriamente";
        }
        if (caja === "-1"){
            mensaje = "Debe seleccionar un destino obligatoriamente";
        }
        if (documento === "-1"){
            mensaje = "Debe seleccionar un documento obligatoriamente";
        }
        if (monto === "-1"){
            mensaje = "Debe ingresar un monto valido obligatoriamente";
        }        
        if (mensaje != ""){
            Swal.fire({
                title: 'Campos pendientes',
                text: mensaje,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Regresar.'
            });  
        }

        let strTipoPago = "" ;
        let image_required = 0;
        dataTipoPago.forEach(function(obj, i){
            if(obj.id == tipoPago){
                strTipoPago = obj.descripcion;
                image_required = obj.validacion_imagen ? obj.validacion_imagen : 0;
                return ;
            }
        });
        if(image_required == "1" && imagenPago == ""){
            Swal.fire({
                title: 'Campos pendientes',
                text: 'Debe ingresar una captura de pantalla obligatoriamente',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Regresar.'
            });  
        }

        if ( 
            tipoPago === "-1" ||
            caja === "-1" ||
            documento === "-1" ||
            monto === "0"
        ){
            /* console.log("NO SE REGISTRO " , tipoPago, caja, documento, monto); */
            Swal.fire({
                title: 'Campos pendientes',
                text: "Verifique que ",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Regresar.'
            });  
            return;
        }
        /** GUARDAMOS TODOS LOS PAGOS EN UNA VARIABLE*/
        let tabla_temp = dataPagos;

        
        let strCaja = "";
        let strDocumento = "";

        let id_documento = 0;
        let id_documento_string = "";
        let id_serie = 0;
        let id_numero = 0;
        
        

        dataCaja.forEach(function(item, i){
            if(item.id == caja ){
                return strCaja = item.descripcion;
            }
        });

        dataDeudas.forEach(function(item, i){
            if(String(item.numDoc).replace(/ /g, "") == documento ){  
                strDocumento = item.doc  + " " +  item.numDoc;
                id_documento =  item.i_iddocumento;
                id_documento_string =  item.doc;
                id_serie =  item.v_serieDocumento;
                id_numero =  item.v_numeroDocumento;
                return ;
            }
        });

        let imageTemp = image_required == "1" ?  await resizeFile(imagenPago) : "";    
        let newFile = "";
        if(image_required == "1"){
            newFile = dataURIToBlob(imageTemp);
        }    
        /* console.log('AL CREAR', imageTemp); */
        

        if(codkey === "0"){
            tabla_temp.push(
                {
                    "akey" : myConstClass.generateId(20) , 
                    "tipopago_cod" : tipoPago , 
                    "tipopago_str" : strTipoPago,
                    "caja_cod" : caja , 
                    "caja_str" : strCaja,
                    "documento_cod" : documento , 
                    "documento_str" : strDocumento , 
                    "monto" : monto , 
                    "id_documento" : id_documento , 
                    "id_documento_string" : id_documento_string , 
                    "id_serie" : id_serie , 
                    "id_numero" : id_numero , 
                    "observacion" : observacion,
                    "image_required" : image_required,                    
                    "imagen_original" : imagenPago,
                    "imagen_comprimida" : newFile,
                    "image_64" : imageTemp,
                    "fecha" : new Date(startDate).toLocaleDateString('en-CA'),    
                    "saldo" : "0"
                }
            )
        }else{
            const modifiedData = tabla_temp.map(item => {
                if (item.akey !== codkey ) {                                                      
                    return item;
                }
                return {
                    ...item,                                
                    "tipopago_cod" : tipoPago , 
                    "tipopago_str" : strTipoPago,
                    "caja_cod" : caja , 
                    "caja_str" : strCaja,
                    "documento_cod" : documento , 
                    "documento_str" : strDocumento , 
                    "monto" : monto , 
                    "id_documento" : id_documento , 
                    "id_documento_string" : id_documento_string , 
                    "id_serie" : id_serie , 
                    "id_numero" : id_numero , 
                    "observacion" : observacion,
                    "image_required" : image_required,                    
                    "imagen_original" : imagenPago,
                    "imagen_comprimida" : newFile,
                    "image_64" : imageTemp,
                    "fecha" : new Date(startDate).toLocaleDateString('en-CA'),           
                    "saldo" : "0"
                };
            });
            tabla_temp = modifiedData;
        }

        
        /* console.log(startDate, tabla_temp); */
        generarSaldosPagos(tabla_temp);

        /* setDataPagos(tabla_temp);
        agruparPagos(tabla_temp); */

        resetFormPago();
        handleCloseModalRegistro();
        /* console.log("REGISTRADO"); */
    }

    const guardarPagos = async () => {
        /* console.log(dataPagos);
        return ; */
        
        setLoading(true);
        let suma_montos = 0;
        dataPagos.forEach(function(item, i){
            suma_montos = parseFloat(suma_montos) + parseFloat(item.monto);
        });

        if(parseFloat(pagado) !== parseFloat(suma_montos)){
            let t_prev = parseFloat(pagado) - parseFloat(suma_montos);
            const isConfirm = Swal.fire({
                title: 'Montos pendientes (S/ '+(Math.floor(t_prev * 100)/100).toFixed(2)+' )',
                text: "Verifique que los montos concuerdan con el monto a Amortizar (S/ "+(Math.floor(pagado * 100)/100).toFixed(2)+")",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Regresar.'
            });            
            return;
        }

        /* Swal.fire({
            icon:"success",
            text:'Pago exitoso'
        })
        handlePrint(); */
        /* navigate("/") */

        /* var _codigos_lista = JSON.stringify(dataPagos); */
        
    
        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('cobrador', myConstClass.VAR_GLOBAL.cobrador);
        for await (const obj of dataPagosGrupo) {
            if(obj.image_required == "1"){
                let file_ = obj.imagen_comprimida;
                formData.append(obj.akey, file_);
            }
        }
        const nuevoTemp = dataPagosGrupo.map(item => { 
            return {
                ...item,
                image_64: "",
                pagos : item.pagos.map(item2 => { 
                    return {
                        ...item2,
                        image_64: ""
                    }
                })

            };
        });
        var _codigos_lista = JSON.stringify(nuevoTemp);        
        formData.append('array_lista', _codigos_lista);
    
        await axios.post(
            `${myConstClass.URL_APP}/api/${myConstClass.VAR_GLOBAL.baseprincipal}/setPagos/${id}`,
            formData
        ).then(({data})=>{
                setDataPrint(data.data);
                Swal.fire({
                    icon:"success",
                    title: data.message,
                    text: "¿Desea imprimir el documento?",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Si, imprimir!'
                }).then((result) => { 
                    if(result.isConfirmed == true){
                        handlePrint();
                    }                                    
                    navigate("/")
                });                

               /*  const isNew = Swal.fire({
                    icon:"success",
                    title: data.message,
                    text: "¿Desea imprimir el documento?",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Si, imprimir!'
                }).then((result) => {
                    console.log(result);
                }); */

                /* swal({
                    title: miData.msj,
                    text: "¿Desea imprimir el documento?",
                    type: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Si, imprimir!'
                }).then(function(result) {
                    if (result.value) {
                        //CERRAMOS EL MODAL
                        imprimir_factura(miData.cod);
                    }
                    swal({
                        title: miData.msj,
                        text: "¿Desea registrar una nuevo Documento?",
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Si, registrar!'
                    }).then(function(result) {
                        if (result.value) {
                            location.reload();
                        } else {

                            window.location.href = "listar-pedidos";
                        }
                    })
                }) */
                
            
        }).catch(({response})=>{                
            /* console.log("ERROR"); */
            if(response.status===422){
                setValidationError(response.data.errors)
            }else{
                Swal.fire({
                text:response.data.message,
                icon:"error"
                })
            }
            setLoading(false);
        })

    }

    /* const createPago = async (e) => {
        e.preventDefault();
        var _codigos_lista = JSON.stringify(dataPagos);
    
        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('cobrador', "8")
    
        await axios.post(
            `${myConstClass.URL_APP}/api/${myConstClass.VAR_GLOBAL.baseprincipal}/setPagos/${id}`,
            formData).then(({data})=>{
                console.log("EXITO");
            }).catch(({response})=>{                
                console.log("ERROR");
                if(response.status===422){
                    setValidationError(response.data.errors)
                }else{
                    Swal.fire({
                    text:response.data.message,
                    icon:"error"
                    })
                }
            })
      } */

    const editarPago = async (cod) => {
        const isConfirm = await Swal.fire({
          title: 'Desea continuar?',
          text: "Va a editar uno de los pagos generados",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, continuar.'
        }).then((result) => {
          return result.isConfirmed
        });
        if(!isConfirm){
          return;
        }
        dataPagos.forEach(function(obj, i){
            if(obj.akey == cod){
                /* console.log(obj); */
                setCodKey(obj.akey)
                setTipoPago(obj.tipopago_cod);
                setDocumento(obj.documento_cod);
                setCaja(obj.caja_cod);        
                setMonto(obj.monto);    
                setObservacion(obj.observacion);
                return;
            }
        });
        handleShowModalRegistro();
    }

    const verPagos = () => {
        console.log('Pagos', dataPagos);
    }

    const updateProduct = async (e) => {
        e.preventDefault();
        Swal.fire({
            icon:"success",
            text: "Pago exitoso"
        })
    }

    

    const columnsDeuda = [        
        /* {
            id: 'index',
            name: 'i',
            maxWidth:'15px !important',
            minWidth:'15px !important',
            selector: row => parseInt(row.indexTemp),
            center: true,
            compact:true,
        }, */
        {
            id: 'document',
            name: 'Documento  Fecha',
            maxWidth:'75px !important',
            minWidth:'75px !important',
            selector: row => row.d_fecha,
            cell: row => (
            	<div>
                    <span className="title_cell_table">{row.doc} {row.numDoc}</span>
                    <br />
                    {row.d_fecha}
            	</div>
            ),
            center: true,
            style: {
            	"padding-left": "0px",                
            	"padding-right": "0px"
            },
        },
        {
            id: 'dias',
            name: 'Dias',
            maxWidth:'30px !important',
            minWidth:'30px !important',
            selector: row => parseInt(row.nrodias),
            center: true,
            compact:true,
        },
        {
            id: 'total',
            name: 'Total',
            maxWidth:'90px !important',
            minWidth:'90px !important',
            selector: row => parseFloat(myConstClass.round(row.d_total, 2)),            
            cell: row => (
                <span> S/. {myConstClass.round_string(row.d_total, 2)}</span>
            ),     
            right: true,
            style: {
            	"padding-left": "8px",                
            	"padding-right": "8px"
            },
        },
        {
            id: 'deuda',
            name: 'Saldo',
            maxWidth:'90px !important',
            minWidth:'90px !important',
            selector: row => parseFloat(myConstClass.round(row.saldoSoles, 2)),
            cell: row => (
                <span id={`cell_deuda-`+ String(row.numDoc).replace(/ /g, "") }> S/. {myConstClass.round_string(row.saldoSoles, 2)}</span>
            ),     
            right: true,
            style: {
            	"padding-left": "8px",                
            	"padding-right": "8px"
            },
        },
        {
            id: 'input',
            name: 'Amortizacion',
            maxWidth:'60px !important',
            minWidth:'initial !important',
            compact:true,
            center:true,
            cell: (record) => {
             return (
                <Form.Group >
                    <Form.Control 
                    type="number" 
                    value={ record.customPagando ? record.customPagando : 0 }
                    className="text-end input-sm inputTableCell input_number_table_pagos" 
                    onClick={handleSelect}
                    disabled={ parseInt(indexComplete) + 1 >= parseInt(record.indexTemp) ? isLoading : true  }
                    onChange = {(event) => {
                        /* console.log(event.target.value); */
                        let _var = event.target.value;
                        let _pago = _var == "" ? 0 : parseFloat(_var);                        
                        /* let _deuda = parseFloat((Math.floor(record.saldoSoles * 100)/100).toFixed(2)); */
                        let _deuda = parseFloat(myConstClass.round(record.saldoSoles, 2));
                        let totalOperacion = 0; 
                        var _element= document.getElementById("cell_deuda-"+ String(record.numDoc).replace(/ /g, ""));
                        if(_pago < 0){
                            _pago = 0;
                        }
                        if(_pago > _deuda){
                            /* console.log("- Pago es mayor q la deuda ", _pago); */
                            /* totalOperacion = Math.floor((_deuda) * 100)/100; */
                            _pago = parseFloat(myConstClass.round(_deuda, 2));
                            /* console.log("Pago es mayor q la deuda ", _pago); */
                        }
                        /* _pago = parseFloat(myConstClass.round(_pago, 2))
                        console.log(_pago); */
                        totalOperacion = _deuda - _pago;
                        _element.innerText = `S/. ${myConstClass.round_string(totalOperacion, 2)}`;
                        const updatedData = dataDeudas.map(item => {
                            if ((String(record.numDoc).replace(/ /g, "")) !== (String(item.numDoc).replace(/ /g, "")) ) {
                                if(item.indexTemp > record.indexTemp && totalOperacion > 0 ){
                                    return {
                                        ...item,                                
                                          customPagando: 0
                                      };
                                }else{                                    
                                    return item;
                                }
                            }else{
                                return {
                                  ...item,
                                    customPagando: _pago
                                };
                            }
                        });
                        setDataDeudas(updatedData);
                        if(totalOperacion === 0){
                            setIndexComplete(record.indexTemp);
                        }else{
                            setIndexComplete(record.indexTemp - 1);
                        }
                        /* console.log(updatedData); */
                        actualizarMontos(updatedData);
                    }}
                    />
                </Form.Group>
             );
            },
            style: {
            	"padding-left": "8px",                
            	"padding-right": "0px"
            },
        },
    ];

    

    const handleSelect = (event) => {
        event.target.select();
    };

    const conditionalRowStyles = [
        {
          when: row => parseFloat(row.customPagando) > 0 && parseFloat( row.customPagando) < parseFloat( row.saldoSoles),
          style: {
            backgroundColor: "rgb(229 251 0)",
            userSelect: "none",
            color: "#000",
          }
        },
        {
            when: row => parseFloat( row.customPagando) === parseFloat( row.saldoSoles),
            style: {
              backgroundColor: "rgb(76 205 18)",
              userSelect: "none",
              color: "#fff",
            }
          },

      ];

  return (
    <div className="container">
        <h4 className="text-center">Estado de cuenta</h4>        
        <h6 className="text-center">{cliente}</h6>
        <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
                <hr />
                <div className="form-wrapper mb-30">
                    {
                        Object.keys(validationError).length > 0 && (
                            <div className="row">
                            <div className="col-12">
                                <div className="alert alert-danger">
                                <ul className="mb-0">
                                    {
                                    Object.entries(validationError).map(([key, value])=>(
                                        <li key={key}>{value}</li>   
                                    ))
                                    }
                                </ul>
                                </div>
                            </div>
                            </div>
                        )
                    }   
                     
                    <Row className=""> 
                        <Col>
                            <Form.Group controlId="deuda">
                                <Form.Label className="text-center">Saldo Total</Form.Label>
                                {/* <Form.Control type="text" value={ `S/ ${parseFloat((Math.floor((deuda-pagado) * 100)/100).toFixed(2))}` } className="text-end" readOnly /> */}
                                <Form.Control type="text" value={ `S/ ${myConstClass.round_string(deuda-pagado, 2)}` } className="text-end" readOnly />
                                {/* <Form.Control type="text" value={ `S/ ${(deuda-pagado)}`} className="text-end" readOnly /> */}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="Pendiente">
                                <Form.Label className="text-center">Amortizacion total</Form.Label>
                                {/* <Form.Control type="text" value={ `S/ ${parseFloat((Math.floor(pagado * 100)/100).toFixed(2))}` } className="text-end" readOnly /> */}
                                <Form.Control type="text" value={ `S/ ${myConstClass.round_string(pagado, 2)}` } className="text-end" readOnly />
                            </Form.Group>
                        </Col> 
                    </Row>
                    <DataTable
                        columns={columnsDeuda}
                        data={dataDeudas}      
                        data-tag="allowRowEvents"                        
                        /* onRowClicked={handleRowClicked} */
                        conditionalRowStyles={conditionalRowStyles}
                    />
                    <hr />
                    <Row className="mb-15"> 
                        <Col xs={8}>
                            <Form.Group className="text-center" controlId="Pendiente_confirm">
                                <Form.Label className="text-center">Amortizacion total</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ `S/ ${parseFloat((Math.floor(pagado * 100)/100).toFixed(2))}` } 
                                    className="text-center bg-primary color-white" 
                                    readOnly 
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4}>                        
                            <div className="d-grid gap-2">
                                <Button 
                                    variant="outline-primary" 
                                    className="btn-lg first" 
                                    type="button"
                                    disabled={ parseFloat(pagado) > 0  ?  isLoading : true }
                                    onClick={handleShowModalRegistro}
                                >
                                    <FontAwesomeIcon icon={faCirclePlus} size="2x" /><br />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <h6 hidden className="text-center text-uppercase"> </h6>       
                    <Row hidden>
                        <Col >                                 
                            <Button variant="outline-info" className="mt-2 btn-sm float-end" 
                                onClick={e => {
                                    /* verPagos(); */
                                    handlePrint();
                                }}
                                size="lg" block="block" type="button">
                                Ver impresion
                            </Button>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                    {
                        dataPagosGrupo.length > 0 && (
                            dataPagosGrupo.map((row, key)=>(
                                <Col className="mb-3 align-self-start" xs={6} key={key}>
                                    <div className="card text-right" >
                                        <div className="card-body">
                                            <h5 className="card-title">S/. {(Math.floor(row.monto * 100)/100).toFixed(2)}</h5>
                                            <strong>{row.caja_str}</strong><br />
                                            <strong>{row.tipopago_str}</strong><br /><br />
                                            {
                                                row.pagos.map((row2, key2)=>(
                                                    <ButtonGroup className="mb-2 cards_pagos" key={key2} size="sm">
                                                        <Button variant="outline-primary" className="btn-sm" type="button" onClick={()=>editarPago(row2.akey)} >
                                                            <FontAwesomeIcon icon={faPenToSquare}/> {row2.documento_str} (S/ {(Math.floor(row2.monto * 100)/100).toFixed(2)})
                                                        </Button>                                                
                                                        <Button variant="outline-danger" className="btn-sm" type="button">
                                                            <FontAwesomeIcon icon={faTrashCan}/>
                                                        </Button>
                                                    </ButtonGroup>
                                                ))
                                            }                                    
                                        </div>
                                    </div>
                                </Col>
                            ))
                        )
                    }
                    </Row>
                    <div className="d-grid gap-2">
                        <Button 
                            variant="success" 
                            className="" 
                            size="lg" block="block" type="button" 
                            onClick={!isLoading ? guardarPagos : null}
                            disabled={ parseFloat(pagado) > 0  ? isLoading : true }
                        >
                            {isLoading ? ' Cargando...' : 'Finalizar y pagar'} 
                        </Button>
                    </div>
                </div>
                {/* <ModalRegistroPago
                keyboard={false}
                    show={modalRegistroShow}
                    onHide={() => setModalRegistroShow(false)}
                /> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6">                    
                {/* <div className="table-responsive mb-15">
                    <table className="table table-bordered mb-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Documento</th>
                                <th>Fecha</th>
                                <th className="text-end">Total</th>
                                <th className="text-end">Saldo</th>
                                <th className="text-end">Amortizando</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataDeudas.length > 0 && (
                                    dataDeudas.map((row, key)=>(
                                        <tr key={key}>
                                            <td>
                                                <Form.Check 
                                                    type="switch"
                                                    id="custom-switch"
                                                    label=""
                                                />
                                            </td>
                                            <td>{row.doc} {row.numDoc}</td>
                                            <td>{row.d_fecha}</td>
                                            <td className="text-end">{ (Math.floor(row.d_total * 100)/100).toFixed(2) }</td>
                                            <td className="text-end">{ (Math.floor(row.saldoSoles * 100)/100).toFixed(2) }</td>
                                            <td className="text-end">
                                                <Form.Group controlId="">
                                                    <Form.Control type="text" className="text-end"/>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    ))
                                )
                            }
                        </tbody>
                    </table>
                </div> */}
            </div>
        </div>
        <section id="print">
            <div 
                ref = {printRef} 
                style={{
                    width: '100%', 
                    height: window.innerHeight
                }}
            >
                {
                    dataPrint.map((row, key)=>(
                        <div key={key} className="ticket">
                            <div key="2"  className="cuerpo">
                                <table className="table mb-0 text-start">
                                    <tbody>
                                        <tr key="cab">
                                            <td key="0" colSpan="2">
                                                <center>
                                                    <strong>
                                                        {myConstClass.VAR_GLOBAL.empresas[myConstClass.VAR_GLOBAL.baseprincipal].fullname}
                                                    </strong>
                                                </center>
                                            </td>
                                        </tr>
                                        <tr key="cab1" className="cab">
                                            <td key="0" colSpan="2">
                                                <center>
                                                    <strong>
                                                        RECIBO DE CANCELACION <br />
                                                        {row.recibo_serie}-{row.recibo_numero}
                                                    </strong>
                                                </center>
                                            </td>
                                        </tr>
                                         <tr key="0" className="data">
                                            <td key="0"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                F.Emi.:
                                            </td>
                                            <td key="2">
                                                {new Date(row.fecha_emision).toLocaleDateString()}
                                            </td>
                                        </tr> 
                                         <tr key="1" className="data">
                                            <td 
                                            key="0"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                Recibi de:
                                            </td>
                                            <td key="1">{row.cliente}</td>
                                        </tr>
                                        <tr key="5" className="data">
                                            <td                                    
                                            key="1"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                Por el Doc.:
                                            </td>
                                            <td                                   
                                            key="2">{row.documento_str}</td>
                                        </tr>
                                        <tr key="2" className="data">
                                            <td 
                                            key="b1"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                Amortizac.:
                                            </td>
                                            <td
                                            key="b2"> S/ {myConstClass.round_string(row.monto, 2)}</td>
                                        </tr>
                                        <tr key="6" className="data">
                                            <td                                    
                                            key="1"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                Saldo act.:
                                            </td>
                                            <td
                                            key="b2"> S/ {myConstClass.round_string(row.saldo_ref, 2)}</td>
                                        </tr>   
                                        <tr key="3" className="data">
                                            <td                                             
                                            key="1"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                T. Pago:
                                            </td>
                                            <td                                   
                                            key="2">
                                                {row.tipopago_string} <br />
                                                {row.caja_str}
                                            </td>
                                        </tr>
                                        <tr key="8" className="data">
                                            <td                                    
                                            key="1"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                Fecha oper.:
                                            </td>
                                            <td key="9">{new Date(row.fecha_operacion).toLocaleDateString()}</td>
                                        </tr>
                                        <tr key="7" className="data">
                                            <td                                    
                                            key="1"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                Cobrador:
                                            </td>
                                            <td key="9">{row.cobrado_por}</td>
                                        </tr>
                                        <tr key="footer" className="data">
                                            <td key="1" colSpan="2">
                                                <center>
                                                    __________________________ <br />
                                                    FIRMA 
                                                </center>
                                            </td>
                                        </tr>
                                        <tr key="footer2" className="data">
                                            <td                                    
                                            key="1"
                                            style={{
                                                width : '18%'
                                            }}
                                            >
                                                Nombre :<br />
                                                DNI :                                                
                                            </td>
                                            <td                                   
                                            key="2">
                                            </td>
                                        </tr>
                                        <tr key="footer3" className="data">
                                            <td key="1" colSpan="2">                                                
                                                <center> 
                                                Impreso : {new Date().toLocaleString() + ""}
                                                </center>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
        <Modal 
            show={modalRegistroShow}
            onHide={handleCloseModalRegistro}            
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >            
            <Modal.Body>
                <h6 className="text-center text-uppercase"> REGISTRO TEMPORAL DE PAGO</h6>
                <Form onSubmit={createPagoTemp}>
                    
                    <Row className="mb-15"> 
                        <Col>
                            <Form.Group className="text-center" controlId="Pendiente_confirm2">
                                <Form.Label className="text-center">Amortizacion</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ `S/ ${parseFloat((Math.floor(pagado * 100)/100).toFixed(2))}` } 
                                    className="text-center bg-primary color-white input-custom-lg" 
                                    readOnly 
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            {/* <Form.Group controlId="recibo">
                                <Form.Label>Numero Recibo</Form.Label>
                                <Form.Control type="text" value="Autogenerado" className="text-center bg-info " readOnly />
                            </Form.Group> */}
                            <Form.Group controlId="recibo">
                                <Form.Label>Fecha</Form.Label>
                                <DatePicker 
                                    className="form-control text-center" dateFormat="dd/MM/yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    />
                                {/* <Form.Control type="text" value="Autogenerado" className="text-center bg-info " readOnly /> */}
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />      
                    <Row className="mb-15"> 
                        <Col>
                            <Form.Group controlId="controlDocumento">
                                <Form.Label>1 Documento</Form.Label>
                                <Form.Control
                                as="select"
                                value={documento}
                                onChange={e => {
                                    let _documento = e.target.value;
                                    setDocumento(_documento);
                                    let amortizar_documento = 0;
                                    let pago_fragm_total = 0;
                                    dataDeudas.forEach(function(item, i){
                                        if(String(item.numDoc).replace(/ /g, "") == _documento ){  
                                            return amortizar_documento = item.customPagando;
                                        }
                                    });

                                    dataPagos.forEach(function(item, i){
                                        if(String(item.documento_cod).replace(/ /g, "") == _documento ){  
                                            pago_fragm_total =  parseFloat(pago_fragm_total) + parseFloat(item.monto);
                                        }
                                    });

                                    let restante = parseFloat(amortizar_documento) - parseFloat(pago_fragm_total);
                                    setMonto(restante)
                                }}
                                >
                                    <option key={`-1`} value={`-1`}>Elija un documento</option>
                                    {dataDeudas.map((_doc, idx) => {                                            
                                        let val_pag = _doc.customPagando ? _doc.customPagando : 0 ;
                                        if(val_pag > 0){
                                            return (
                                                <option 
                                                    key={idx} 
                                                    value={String(_doc.numDoc).replace(/ /g, "")}
                                                > 
                                                    S/ {_doc.customPagando} | {_doc.doc} {_doc.numDoc}
                                                </option>
                                            );
                                        }
                                    } )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="Name">
                                <Form.Label >2 Monto a Pagar</Form.Label>
                                {/* <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"></InputGroup.Text>
                                    <Form.Control
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    />
                                </InputGroup> */}
                                <InputGroup size="sm">
                                    <InputGroup.Text >S/ </InputGroup.Text>
                                    <Form.Control type="text" value={monto} className="text-end" onChange={(event)=>{
                                        setMonto(event.target.value);
                                        /* let valor = event.target.value;
                                        setMonto(valor);
                                        var s= document.getElementById("controlSaldoNuevo");
                                        s.value = deuda - valor; */
                                    }} />
                                </InputGroup> 
                                {/* <Form.Control type="text" value={monto} className="text-end" onChange={(event)=>{
                                    setMonto(event.target.value);
                                }} /> */}
                            </Form.Group>
                        </Col>
                    </Row>              
                    <Row className="mb-15">                             
                        <Col>                            
                            <Form.Group controlId="labelTipoPago">
                                <Form.Label>3 Tipo Pago</Form.Label>
                            </Form.Group>
                            <ButtonGroup size="sm">
                                {dataTipoPago.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio_tipopago-${radio.id}`}
                                        type="radio"
                                        variant={'outline-primary'}
                                        name="radioTipoPago"
                                        value={radio.id}
                                        checked={tipoPago === radio.id}
                                        onChange={(e) => setTipoPago(e.currentTarget.value)}
                                    >
                                        {radio.descripcion}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row className="mb-15">
                        <Col>
                            <Form.Group controlId="controlDestino">
                                <Form.Label>4 Destino</Form.Label>
                                <Form.Control
                                as="select"
                                value={caja}
                                onChange={e => {
                                    setCaja(e.target.value);
                                }}
                                >
                                    <option key={`-1`} value={`-1`}>Elija un destino</option>
                                    {dataCaja.map((caja, idx) => (
                                        <option key={idx} value={caja.id}>{caja.descripcion}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>             
                    <Row>
                        <Col>
                            <Form.Group controlId="Image" className="mb-3">
                                <Form.Label>5 Image</Form.Label>
                                <Form.Control 
                                    type="file"                             
                                    accept="image/*"
                                    onChange={changeHandlerImage} 
                                />
                            </Form.Group>
                            <br />
                            <Form.Group controlId="Observacion">
                                <Form.Label>6 Observacion</Form.Label>
                                <Form.Control as="textarea" rows={2} value={observacion} onChange={(event)=>{
                                    setObservacion(event.target.value);
                                }}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="Preview">
                                <Form.Label><strong>Preview</strong></Form.Label>
                            </Form.Group>
                            <img className="w-100" src={imageView} alt="" />
                        </Col> 
                    </Row>

                    <Button variant="success" className="mt-2 btn-sm" size="lg" block="block" type="submit" /* onClick={() => createPagoTemp()} */>
                        Registrar
                    </Button>
                    <Button 
                        variant="outline-primary" 
                        className="mt-2 btn-sm float-end" 
                        size="lg" 
                        block="block" 
                        type="button"
                        onClick={handleCloseModalRegistro}
                    >
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    </div>
  )
}
